@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
:root {
  --purple: #865ddd;
  --yellow: #865ddd;
  --grey: #707070;
  --light-grey: #c8c8c8;
  --light-purple: #f9f6ff;
  --white: #ffffff;
}

.back-img-login {
  background-image: url(/static/media/login-back.2116648e.jpg);
  min-height: 752px;
  background-repeat: no-repeat;
  /* background-size: cover; */
  background-position: 30% 120%;
}
@media only screen and (max-width: 700px) {
  .back-img-login {
    background-image: url(/static/media/login-back.2116648e.jpg);
    min-height: 500px !important;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.main-login-form {
  background-color: #ffff;
  border-radius: 10px;
}

.main-login-form {
  margin-top: 50px;
}

.recr-login-head {
  color: #865ddd;
  color: var(--purple);
  font-weight: bold;
  font-size: 30px;
  text-align: center;
}

.recr-login-input {
  background: transparent;
  border: none;
  border-bottom: 1px solid #c8c8c8;
  box-shadow: none;
  border-radius: 0;
  padding: 0;
  color: black;
  font-size: 14px;
  margin: 0;
  padding: 0;
}

.recr-login-label {
  color: #707070;
  color: var(--grey);
}

.forget-pass-login {
  font-size: 12px;
  text-align: right;
  color: #865ddd;
  color: var(--purple);
}

.login-recr-btn {
  color: #fff !important;
  background-color: #865ddd !important;
  font-weight: bold !important;
  border: 0;
}

.login-recr-btn:hover {
  background-color: #865ddd !important;
}

.login-recr-btn:focus {
  background-color: #865ddd !important;
}

.login-recr-btn:active {
  background-color: #865ddd !important;
}

.horizon-rule-reg {
  border: 1px solid #c8c8c8;
  border: 1px solid var(--light-grey);
}

.icon-radius-reg {
  border-radius: 40%;
  cursor: pointer;
}

.dont-account-login {
  font-size: 14px;
  text-align: center;
  color: black;
}

.reg-here-login {
  color: #865ddd;
  font-weight: bold;
}

.form-control:focus {
  box-shadow: none !important;
  border-color: none !important;
  color: black !important;
}

.main-li-of-nav{
  font-size: 12px;
  color: #707070 !important;
}

.table-text0-small {
  font-size: 12px;
}

.text-link a {
  color: rgb(177, 177, 177);
}

.text-link a:hover {
  color: #fca120;
}

.fp-container{
    position: fixed;
    display: flex;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: #f8f8f8ad;
}

.fp-container .fp-loader{
    display: flex;
    justify-content: center;
    align-items: center !important;
    z-index: 1000;
    top: 40%;
    left: 35%;
    position: absolute;
}
.fp-container .fp-loaders{
    display: flex;
    justify-content: center;
    align-items: center !important;
    z-index: 1000;
    top: 40%;
    position: absolute;
}
:root {
  --purple: #865ddd;
  --yellow: #865ddd;
  --grey: #707070;
  --light-grey: #c8c8c8;
  --light-purple: #f9f6ff;
  --white: #ffffff;
  --blue: #011f95;
}

.head-pack-main-premium {
  text-align: center;
  font-weight: bold;
  color: #707070;
  color: var(--grey);
}
.cash-stan-main {
  color: #865ddd;
  font-weight: bold;
}
.text-ofo-pack-prem {
  font-size: 14px;
  color: #707070;
  color: var(--grey);
}

.num-ofo-pack-prem {
  font-size: 14px;
  font-weight: bold;
  color: #865ddd;
  color: var(--purple);
}

.price-of-premium-pack {
  text-align: center;
  font-weight: bold;
  color: #865ddd;
  color: var(--purple);
}
.small-month {
  font-size: 20px;
  color: #707070;
  font-weight: bold;
}

.month-of-premium {
  font-size: 14px;
  color: #c8c8c8;
  color: var(--light-grey);
}

.style-4 del {
  /* color: red; */
  text-decoration: none;
  position: relative;
  /* font-size: 40px;
  font-weight: 100; */
}
.style-4 del:before {
  content: " ";
  display: block;
  width: 100%;
  border-top: 3px solid red;
  height: 8px;
  position: absolute;
  bottom: 20px;
  left: 0;
  -webkit-transform: rotate(-11deg);
          transform: rotate(-11deg);
}
.style-4 del:after {
  content: " ";
  display: block;
  width: 100%;
  border-top: 3px solid red;
  height: 8px;
  position: absolute;
  bottom: 20px;
  left: 0;
  -webkit-transform: rotate(11deg);
          transform: rotate(11deg);
}
.main-package-box {
  /* min-height: 500px; */
}
.package-types .type {
  min-height: 150px;
  border-radius: 12px;
  padding: 10px;
}
.package-types .type.premium {
  background-color: #865ddd;
  color: #ffffff;
  color: var(--white);
}
.package-types .type.basic {
  border: 2px solid #f7a02e;
  color: #c8c8c8;
  color: var(--light-grey);
}
.package-types .type i {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.package-types .type.basic i {
  color: #f7a02e;
  background-color: #f7ead8;
}
.package-types .type.premium i {
  color: #865ddd;
  background-color: #e2d8f7;
}
.package-types .type h4 {
  font-size: 16px;
  font-weight: 900;
  margin-top: 10px;
}
.package-types .type h1 {
  font-size: 25px;
  font-weight: 900;
  margin-top: 10px;
  /* word-break: break-word; */
}
.package-types .type h1 span {
  font-size: 14px;
  font-weight: 900;
  margin-top: 10px;
}
.features .feature p {
  color: #707070;
  color: var(--grey);
  margin: 0;
}
.features .checks {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.features .checks i {
  font-size: 20px;
  color: #c8c8c8;
  color: var(--light-grey);
}
.features .checks i.checked {
  color: #865ddd;
}
.head h1 {
  color: #c8c8c8;
  color: var(--light-grey);
  font-weight: 900;
}
.head p {
  color: #c8c8c8;
  color: var(--light-grey);
}

#btn-nav-user {
  background-color: #865ddd !important;
  color: #fff;
  border-radius: 5px;
}

.text-of-nav-cand {
  font-size: 12px;
}

.main-li-of-nav {
  font-size: 12px;
  color: #707070 !important;
}
.linkhighlight {
  color: blue !important;
}

.bg-imgage {
  background-image: url(/static/media/modalBG.f9e50589.png);
  height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
}

:root {
  --purple: #865ddd;
  --yellow: #865ddd;
  --grey: #707070;
  --light-grey: #c8c8c8;
  --light-purple: #f9f6ff;
  --white: #ffffff;
}

.back-img-reg {
  background-image: url(/static/media/register-back.2bcaae6a.jpg);
  min-height: 700px;
  background-repeat: no-repeat;
  background-size: cover;
}
.back-img-regs {
  background-image: url(/static/media/remob.7ab78b2b.jpg);
  min-height: 700px;
  background-repeat: no-repeat;
  background-size: cover;
}

.main-reg {
  background-color: #fff;
  border-radius: 10px;
  margin-top: 50px;
}

.reg-head {
  font-weight: bold;
  color: #865ddd;
  color: var(--purple);
  text-align: center;
}

#input-reg,
.input-reg {
  background: transparent;
  border: none;
  border-bottom: 1px solid #c8c8c8;
  box-shadow: none;
  border-radius: 0;
  padding: 0;
  color: #c8c8c8;
  color: var(--light-grey);
  font-size: 14px;
  margin: 0;
  padding: 0;
}

.label-reg {
  color: #707070;
  color: var(--grey);
}

.recr-text-reg {
  text-align: center;
  font-size: 12px;
  color: #707070;
  color: var(--grey);
}

.term-priv-reg {
  color: #865ddd;
  color: var(--purple);
  text-decoration: underline;
}

.reg-btn-main {
  color: #fff !important;
  background-color: #865ddd !important;
  font-weight: bold !important;
  border: 0;
}

.reg-btn-main:hover {
  background-color: #865ddd !important;
}

.reg-btn-main:focus {
  background-color: #865ddd !important;
}

.reg-btn-main:active {
  background-color: #865ddd !important;
}
.p-dropdown {
  width: 100%;
}
.p-dropdown .p-dropdown-trigger {
  display: none;
}
.p-dropdown .p-dropdown-label.p-inputtext {
  padding: 6px;
}
.intl-tel-input {
  width: 100%;
  margin-top: 8px;
}

:root {
  --purple: #865ddd;
  --yellow: #865ddd;
  --grey: #707070;
  --light-grey: #c8c8c8;
  --light-purple: #f9f6ff;
  --white: #ffffff;
}

.img-center {
  text-align: center;
}

.main-forget-recr {
  background-color: #fff;
  border-radius: 10px;
}

.for-recr-head {
  color: #865ddd;
  color: var(--purple);
}

.forget-pass-head-01 {
  color: #865ddd;
  color: var(--purple);
  font-size: 25px;
  text-align: center;
}

.para-forget-pass {
  font-size: 12px;
  text-align: center;
  color: #707070;
  color: var(--grey);
}

.label-color-f-pass {
  color: #707070;
  color: var(--grey);
}

#input-forget-pass {
  background: transparent;
  border: none;
  border-bottom: 1px solid #c8c8c8;
  box-shadow: none;
  border-radius: 0;
  padding: 0;
  color: #c8c8c8;
  color: var(--light-grey);
  font-size: 14px;
  margin: 0;
  padding: 0;
}

.login-instead-for-pass {
  font-size: 12px;
  text-align: right;
  color: #865ddd;
  color: var(--purple);
  cursor: pointer;
}

.send-btn-for-pass {
  color: #fff !important;
  background-color: #865ddd !important;
  font-weight: bold !important;
  border: 0;
}

.send-btn-for-pass:hover {
  background-color: #865ddd !important;
}

.send-btn-for-pass:focus {
  background-color: #865ddd !important;
}

.send-btn-for-pass:active {
  background-color: #865ddd !important;
}

:root {
  --purple: #865ddd;
  --yellow: #865ddd;
  --grey: #707070;
  --light-grey: #c8c8c8;
  --light-purple: #f9f6ff;
  --white: #ffffff;
}

.terms-and-cond-head {
  color: #865ddd;
  color: var(--purple);
  text-align: left;
  font-weight: bold;
}

.para-terns-and-cond {
  color: #707070;
  color: var(--grey);
}

.term-con-heading {
  font-weight: 800;
  text-transform: uppercase;
}
:root {
  --purple: #865ddd;
  --yellow: #865ddd;
  --grey: #707070;
  --light-grey: #c8c8c8;
  --light-purple: #f9f6ff;
  --white: #ffffff;
}

.privacy-policy-head {
  color: #865ddd;
  color: var(--purple);
  text-align: left;
  font-weight: bold;
}

.privacy-policy-text {
  color: #707070;
  color: var(--grey);
}

:root {
  --purple: #865ddd;
  --yellow: #865ddd;
  --grey: #707070;
  --light-grey: #c8c8c8;
  --light-purple: #f9f6ff;
  --white: #ffffff;
  --blue: #011f95;
}
a {
  text-decoration: none;
  color: black;
}
a:hover {
  color: black;
  text-decoration: none;
}
.home-back-img {
  background-image: url(/static/media/dashboard_banner.9ce75b63.jpg);
  min-height: 390px;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: contain;
  position: relative;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}

.white-hpme {
  color: #fff;
}

.home-maein-back-search {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
}

.btn-main-home-recr {
  color: #fff !important;
  background-color: #865ddd !important;
  font-weight: bold !important;
  border: 0;
}

.btn-main-home-recr:hover {
  background-color: #865ddd !important;
}

.btn-main-home-recr:focus {
  background-color: #865ddd !important;
}

.btn-main-home-recr:active {
  background-color: #865ddd !important;
}

.link-tag-home:hover {
  text-decoration: none !important;
}

.home-hover-effect:hover {
  background: rgba(0, 0, 0, 0.1);
}

:root {
  --purple: #865ddd;
  --yellow: #865ddd;
  --grey: #707070;
  --light-grey: #c8c8c8;
  --light-purple: #f9f6ff;
  --white: #ffffff;
}

.contact-head-recr {
  color: #865ddd;
  color: var(--purple);
  font-weight: bold;
}

.para-recr {
  color: #707070;
  color: var(--grey);
}

.label-of-contact-recr {
  color: #707070;
  color: var(--grey);
}

.text-area-contact-recr {
  min-height: 200px;
}

.input-contact-form {
  color: #c8c8c8 !important;
  color: var(--light-grey) !important;
}

.sub-con-btn {
  color: #fff !important;
  background-color: #865ddd !important;
  font-weight: bold !important;
  border: 0;
  margin-top: 25px;
  margin-bottom: 20px;
}

.sub-con-btn :hover {
  background-color: #865ddd !important;
}

.sub-con-btn :focus {
  background-color: #865ddd !important;
}

.sub-con-btn :active {
  background-color: #865ddd !important;
}

.form-control::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #c8c8c8;
  opacity: 1; /* Firefox */
}

.form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #c8c8c8;
  opacity: 1; /* Firefox */
}

.form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #c8c8c8;
}

.form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #c8c8c8;
}
/* .btn-primary{
  background-color: var(--purple);
} */
:root {
  --purple: #865ddd;
  --yellow: #865ddd;
  --grey: #707070;
  --light-grey: #c8c8c8;
  --light-purple: #f9f6ff;
  --white: #ffffff;
}

.create-job-recr-head {
  color: #865ddd;
  color: var(--purple);
  text-align: center;
  font-weight: bold;
}

.create-job-main-recr {
  border-radius: 10px;
}

.label-create-job-recr {
  color: #707070;
  color: var(--grey);
}

.salary-range-recr {
  color: #707070;
  color: var(--grey);
  font-weight: bold;
}

.recr-craete-job-select {
  color: #c8c8c8 !important;
  color: var(--light-grey) !important;
}

.create-job-btn-recr {
  color: #fff !important;
  background-color: #865ddd !important;
  font-weight: bold !important;
  border: 0;
  margin-top: 25px;
  margin-bottom: 20px;
}

.create-job-btn-recr :hover {
  background-color: #865ddd !important;
}

.create-job-btn-recr :focus {
  background-color: #865ddd !important;
}

.create-job-btn-recr :active {
  background-color: #865ddd !important;
}

:root {
  --purple: #865ddd;
  --yellow: #865ddd;
  --grey: #707070;
  --light-grey: #c8c8c8;
  --light-purple: #f9f6ff;
  --white: #ffffff;
}

.about-us-image {
  background-image: url(/static/media/back-img.f7685bf8.jpg);
  min-height: 350px;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: contain;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}

.about-head {
  color: #865ddd;
  color: var(--purple);
  font-weight: bold;
}

:root {
  --purple: #865ddd;
  --yellow: #865ddd;
  --grey: #707070;
  --light-grey: #c8c8c8;
  --light-purple: #f9f6ff;
  --white: #ffffff;
  --blue: #011f95;
  --primary-color: #865ddd;
  --list-color: #011f95;
  --box-heading-color: #909090;
  --back-button-color: #c8c8c8;
  --ad-banner-color: #d8e8ff;
  --ad-banner-text-color: #c5d2e5;
}

.text-color {
  color: #707070;
  color: var(--grey);
  font-weight: bold;
}
.text-disabled {
  color: #c8c8c8;
  color: var(--light-grey);
}

.text-disabled-02 {
  color: #707070;
  color: var(--grey);
}

body {
  height: 100%;
  background-color: #fff;
}
.search-top {
  padding-top: 50px;
}
.info-tags span {
  display: inline-block;
  margin: 00px 10px;
  font-size: 12px;
}
.info-tags {
  margin: 10px 0px;
}
.job_result p {
  font-size: 12px;
  margin: 0 !important;
}
.end {
  justify-content: flex-end;
  display: flex;
}
.start {
  justify-content: flex-start;
}
.job_result ul li {
  font-size: 12px;
}
.search-top .row .col-md-2 a {
  font-size: 24px;
  text-decoration: none;
  color: #7a7a7a;
  font-weight: bold;
}
.search-top .row .col-md-9 h1 {
  color: #865ddd;
  color: var(--primary-color);
  font-weight: bold;
}
.search-top .row .col-md-9 h6 {
  color: #011f95;
  color: var(--list-color);
  font-weight: 600;
}
.padder {
  padding-top: 40px;
  padding-left: 100px;
  padding-bottom: 50px;
}
.select-area {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}
.select-area button {
  margin-bottom: 20px;
  padding: 5px 10px;
  width: 100%;
  text-align: left;
  background: #fff;
  height: 40px;
  color: #011f95;
  color: var(--list-color);
  font-weight: bold;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1), 0px -3px 5px rgba(0, 0, 0, 0.1);
  outline: none;
  border: none;
  border-radius: 10px;
}
.select-area select {
  color: #011f95;
  color: var(--list-color);
  background: #fff;
  padding: 5px 10px;
  width: 100%;
  font-weight: 500;
  -webkit-appearance: none;
  appearance: none;
  background: url(/static/media/down.7e5b65af.png);
  background-size: 20px;
  background-position: 95%;
  background-repeat: no-repeat;
  margin-bottom: 20px;
  height: 44px;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1), 0px -3px 5px rgba(0, 0, 0, 0.1);
  outline: none;
  border: none;
  border-radius: 10px;
}
.job_result {
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1), 0px -3px 5px rgba(0, 0, 0, 0.001);
  padding: 25px;
  border-radius: 10px;
  margin-bottom: 15px;
}
.job_result:hover {
  background: rgba(0, 0, 0, 0.1);
}
.info-tags {
  margin-bottom: 15px;
}
.place-ad {
  height: 700px;
  max-width: 230px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1), 0px -3px 5px rgba(0, 0, 0, 0.1);
  background: #d8e8ff;
  background: var(--ad-banner-color);
}
.place-ad h4 {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
  text-align: center;
  color: rgba(0, 0, 0, 0.4);
  font-size: 26px;
}

.posted-today-text-rexr-o1 {
  text-align: right;
  color: #707070;
  color: var(--grey);
}

ul {
  margin: 0px;
  margin-left: 20px;
}
@media (max-width: 991px) and (min-width: 767px) {
  .select-area select {
    background-image: none;
    -webkit-appearance: auto;
            appearance: auto;
  }
}
@media (max-width: 767px) {
  .container-bottom {
    padding-left: 15px;
  }
}

.posted-jobs-rexr-head {
  font-weight: bold;
  color: #865ddd;
  color: var(--purple);
}

.posted-jobs-rexr-para {
  color: #011f95;
  color: var(--blue);
  font-weight: bold;
}

.link-tag-home:hover {
  text-decoration: none !important;
}

/* Styles for .pagination-container */
.pagination-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: left;
  margin-top: 50px;
}

/* Styles for .results inside .pagination-container */
.pagination-container .results {
  margin: 10px 0;
}

/* Styles for .page-link */
.pagination-container .page-link:hover {
  color: white;
  background-color: #4e46ef;
  cursor: pointer;
}

/* Styles for .selected-page */
.pagination-container .selected-page {
  color: white;
  background-color: #4e46ef;
}

.delete-icon-hover {
  position: relative;
  display: inline-block;
  padding: 10px;
  cursor: pointer;
}

.delete-icon-hover::after {
  content: "Delete Job";
  position: absolute;
  width: 76px;
  top: -60%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  font-size: 14px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s, visibility 0.2s;
}

/* Show the tooltip on hover */
.delete-icon-hover:hover::after {
  opacity: 1;
  visibility: visible;
}

:root {
  --purple: #865ddd;
  --yellow: #865ddd;
  --grey: #707070;
  --light-grey: #c8c8c8;
  --light-purple: #f9f6ff;
  --white: #ffffff;
  --blue: #011f95;
}

.app-cand-head {
  color: #707070;
  color: var(--grey);
}

.light-color-app-cadidate{
    color: #c8c8c8;
    color: var(--light-grey);
}

.dark-color-app-cand{
    color: #707070;
    color: var(--grey);
}

.button-of-app-cand {
    color: #fff !important;
    background-color: #FFB44A !important;
    font-weight: bold !important;
    border: 0;
    margin-top: 25px;
  }
  
  .button-of-app-cand :hover {
    background-color: #FFB44A !important;
  }
  
  .button-of-app-cand :focus {
    background-color: #FFB44A !important;
  }
  
  .button-of-app-cand :active {
    background-color: #FFB44A !important;
  }
:root {
  --purple: #865ddd;
  --yellow: #865ddd;
  --grey: #707070;
  --light-grey: #c8c8c8;
  --light-purple: #f9f6ff;
  --white: #ffffff;
  --blue: #011f95;
}

.card-main-app-can {
  /* background-color: aqua; */
  height: 170px;
  width: 1200px;
 overflow-y: auto;
}

.profile-card-user-one {
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 10px;
  margin-right: 10px;
  /* background-color: red; */
  border-radius: 10px;
}

.can-name-app-can {
  color: #707070;
  color: var(--grey);
  margin-top: 10px !important;
}

.can-loc-app-can {
  color: #c8c8c8;
  color: var(--light-grey);
}

.skill-app-cen {
  color: #707070;
  color: var(--grey);
}

.user-desc-prof-card {
  color: #c8c8c8;
  color: var(--light-grey);
  font-size: 12px;
}

.img-comp-app-can {
  margin-top: 10px !important;
}


.card-block {
  min-height: 200px;
}

.main-row-app-cand{
  overflow: auto;
  /* background-color: #865ddd; */
}
:root {
  --purple: #865ddd;
  --yellow: #865ddd;
  --grey: #707070;
  --light-grey: #c8c8c8;
  --light-purple: #f9f6ff;
  --white: #ffffff;
  --blue: #011f95;
  --primary-color: #865ddd;
  --list-color: #011f95;
  --box-heading-color: #909090;
  --back-button-color: #c8c8c8;
  --ad-banner-color: #d8e8ff;
  --ad-banner-text-color: #c5d2e5;
  --red: #e22929;
}

.closed-color-of-jobs {
  text-align: right;
  color: #e22929;
  color: var(--red);
  font-size: 20px !important;
}

.default-drop-btn {
  color: #011f95 !important;
  background-color: #fff !important;
  border: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding-right: 100px;
  padding-top: 10px;
  padding-bottom: 10px; */
}

.default-drop-btn :hover {
  background-color: #fff !important;
}

.default-drop-btn :focus {
  background-color: #fff !important;
}

.default-drop-btn :active {
  background-color: #fff !important;
}

:root {
  --purple: #865ddd;
  --yellow: #865ddd;
  --grey: #707070;
  --light-grey: #c8c8c8;
  --light-purple: #f9f6ff;
  --white: #ffffff;
  --blue:#011F95;
}

.applicants-head-recr {
  color: #865ddd;
  color: var(--purple);
  font-weight: bold;
}

.applicants-para-recr{
    color: #011F95;
    color: var(--blue);
    font-weight: bold;
}

.recr-user-info-main{
    background-color: #fff;
    border-radius: 10px;
    cursor: pointer;
}

.user-name-recr{
    font-size: 25px;
    color: #707070;
    color: var(--grey);
    font-weight: bold;
    margin-top: 25px !important;
}

.user-loc-recr{
    color: #c8c8c8;
    color: var(--light-grey);
}

.key-skills-recr-applicants{
    font-size: 20px;
    color: #707070;
    color: var(--grey);
    font-weight: bold;
    margin-top: 10px !important;
}

.userskills-appli{
    font-size: 14px;
    color: #c8c8c8;
    color: var(--light-grey);
}

.posted-date-appli{
    color: #c8c8c8;
    color: var(--light-grey);
    margin-top: 15px !important;
}

.overflow-applicants{
    height: 700px;
    overflow: auto;
}

.footer-applicant{
    margin-top: 100px !important;
}

.link-tag-home:hover {
    text-decoration: none !important;
  }

  .home-hover-effect:hover{
    background: rgba(0, 0, 0, 0.1);
  }
  
:root {
  --purple: #865ddd;
  --yellow: #865ddd;
  --grey: #707070;
  --light-grey: #c8c8c8;
  --light-purple: #f9f6ff;
  --white: #ffffff;
  --blue: #362658;
}

.applicants-video-head-recr {
  color: #865ddd;
  color: var(--purple);
  font-weight: bold;
}

.edit-text-rexr {
  text-align: right;
  color: #865ddd;
  color: var(--purple);
  font-size: 20px;
}

.edit-btn-apll-vid-rexr{
    background-color: #fff;
    border: 0;
    color: #865ddd;
    color: var(--purple);
    font-weight: bold;
    font-size: 20px;
}

.applic-stat-recr {
  color: #865ddd;
  color: var(--purple);
  font-size: 20px;
  font-weight: bold;
}

.app-stst-para {
  color: #707070;
  color: var(--grey);
  font-size: 12px;
}

.shortlist-text-rexr {
  color: #865ddd;
  color: var(--purple);
  font-weight: bold;
}

.prog-up-recr {
  color: #707070;
  color: var(--grey);
  font-weight: bold;
}

.name-applicants-recr {
  font-size: 25px;
  color: #362658;
  color: var(--blue);
  font-weight: bold;
}

.date-of-appli-rexr {
  text-align: right;
  font-size: 12px;
  color: #707070;
  color: var(--grey);
}

.prof-appli-rexr {
  font-weight: bold;
  color: #707070;
  color: var(--grey);
}

.loc-recr-vid {
  color: #707070;
  color: var(--grey);
  font-size: 14px;
  /* font-weight: bold; */
}

.view-prof-btn-rexr {
  color: #fff !important;
  background-color: #865ddd !important;
  font-weight: bold !important;
  border: 0;
}

.view-prof-btn-rexr:hover {
  background-color: #865ddd !important;
}

.view-prof-btn-rexr:focus {
  background-color: #865ddd !important;
}

.view-prof-btn-rexr:active {
  background-color: #865ddd !important;
}
@media only screen and (max-width:712px){
  .ReactModal__Content{
    width: 340px !important;
  }
}

:root {
  --purple: #865ddd;
  --yellow: #865ddd;
  --grey: #707070;
  --light-grey: #c8c8c8;
  --light-purple: #f9f6ff;
  --white: #ffffff;
}

.edit-prof-text {
  font-weight: bold;
  color: #707070;
  color: var(--grey);
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 20px;
}

.head-over-flow-app-prof-recr {
  /* overflow: auto; */
  /* height: 150px; */
}

.loceditprof-appl-prof {
  color: #707070;
  color: var(--grey);
  /* font-weight: bold; */
  font-size: 14px;
}

.cv-video-head-rcvr {
  color: #865ddd;
  color: var(--purple);
  font-weight: bold;
}

.para-head-vid-revr {
  color: #707070;
  color: var(--grey);
}

.main-border-rad-editprof{
  border-radius: 15px;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.btn-color-key {
  color: #707070;
  color: #707070;
  color: var(--grey);
  background-color: #f9f6ff;
  background-color: #f9f6ff;
  background-color: var(--light-purple);
  font-weight: 700;
}
:root {
  --purple: #865ddd;
  --yellow: #ffb44a;
  --grey: #707070;
  --light-grey: #c8c8c8;
}

.company-profile-parent {
  width: 100%;
  height: 193vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  /* background-color: rgb(115, 238, 84); */
}

.company-det-div {
  margin-top: 20px;
  height: 30vh;
  width: 80%;
  display: flex;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 5px 5px #f2f2f2;
}

.company-profile-pic {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.comp-det-profile {
  width: 80%;
  height: 100%;
  /* background-color: burlywood; */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.profile-pic-company {
  height: 150px;
  width: 150px;
  background-color: rgb(153, 243, 255);
  border-radius: 50%;
}

.color-head-tech {
  color: #707070;
  color: var(--grey);
}

.color-head-para {
  color: #707070;
  color: var(--grey);
  font-size: 14px;
}

.color-head-para-light {
  color: #c8c8c8;
  color: var(--light-grey);
  font-size: 14px;
}

.all-details-main-company {
  margin-top: 20px;
  height: 100vh;
  width: 80%;
  /* background-color: crimson; */
  display: flex;
}

.main-comp-child-01 {
  height: 100%;
  width: 30%;
  /* background-color: cyan; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.main-comp-child-02 {
  height: 100%;
  width: 70%;
  /* background-color: rgb(255, 42, 209); */
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.comp-profile-main-div {
  height: 100%;
  width: 90%;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 5px 5px #f2f2f2;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  padding-left: 20px;
}

.latest-jobs-color-profile {
  color: #707070;
  color: var(--grey);
}

.comp-profile-latest-jobs {
  height: 85%;
  width: 95%;
  /* background-color: darkred; */
  display: flex;
  flex-direction: column;
  overflow: auto;
  margin-top: 30px;
}

.latest-jobs-01 {
  width: 100%;
  min-height: 20%;
  border-radius: 10px;
  background-color: #f8f8f8;
  display: flex;
  margin-top: 10px;
  box-shadow: 0 0 2px 2px #f2f2f2;
  padding-left: 5px;
  padding-right: 5px;
}

.latest-job-detail {
  height: 100%;
  width: 80%;
  /* background-color: darkviolet; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 20px;
}

.latest-job-date {
  height: 100%;
  width: 20%;
  /* background-color: dimgray; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 14px;
}

.main-comp-sub-child-01 {
  height: 48%;
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 5px 5px #f2f2f2;
  display: flex;
  flex-direction: column;
}

.main-comp-sub-child-02 {
  height: 48%;
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 5px 5px #f2f2f2;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.heading-main-comp-sub-child {
  color: #707070;
  color: var(--grey);
  padding-top: 10px;
  padding-left: 10px;
}

.about-desc-content {
  height: 80%;
  width: 95%;
  /* background-color: darkgreen; */
  margin-left: 10px;
  overflow: auto;
  padding-right: 10px;
}

.company-profile-about-para {
  font-size: 13px;
  color: #707070;
}

.main-comp-tit-child-01 {
  height: 20%;
  width: 100%;
  /* background-color: darkmagenta; */
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.main-comp-tit-child-02 {
  height: 20%;
  width: 100%;
  /* background-color: rgb(238, 124, 238); */
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.main-comp-tit-child-03 {
  height: 20%;
  width: 100%;
  /* background-color: darkmagenta; */
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.main-comp-tit-child-04 {
  height: 20%;
  width: 100%;
  /* background-color: rgb(245, 167, 245); */
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.main-comp-tit-child-05 {
  height: 20%;
  width: 100%;
  /* background-color: darkmagenta; */
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.main-tit-head {
  color: #707070;
  color: var(--grey);
}

.main-tit-head-02 {
  color: #c8c8c8;
  color: var(--light-grey);
}

.job-detail-comp-para {
  color: #707070;
  color: var(--grey);
  font-weight: bold;
  font-size: 14px;
}

.company-name-profile-main {
  color: #c8c8c8;
  color: var(--light-grey);
  padding-top: 10px;
}

.date-of-job-posted {
  color: #707070;
  color: var(--grey);
}

.btn-comp-prof-rcer {
  background-color: #fff;
  border: 0;
  color: #865ddd;
  color: var(--purple);
  font-weight: bold;
}
.input-label {
  margin-top: 10px;
}

.video-container {
  align-items: center;
  padding-bottom: 10px;
}

/* Hide the default file input appearance */
#fileInput {
  display: none;
}

/* Style the custom label to look like a button */
label {
  display: inline-block;
  padding: 7px 20px;
  color: #fff;
  border-radius: 5px;
}

/* Change label appearance when hovering over it */

/* Change label appearance when the input is focused */
#fileInput:focus + label {
  box-shadow: 0 0 5px 2px #007bff;
}

.delete-button {
  margin: 10px 0;
  padding: 7px 12px;
  background-color: #865ddd;
  color: white;
  text-align: center;
  border-radius: 5px;
}

.delete-button:hover {
  cursor: pointer;
  background-color: #6d4db3;
}

:root {
  --purple: #865ddd;
  --yellow: #865ddd;
  --grey: #707070;
  --light-grey: #c8c8c8;
  --light-purple: #f9f6ff;
  --white: #ffffff;
  --blue: #011f95;
}

.edit-prof-head-main {
  color: #865ddd;
  color: var(--purple);
  font-weight: bold;
}

.replace-photo-txt-edit-prof {
  text-align: center;
  margin-top: 20px;
}

.label-color-edit-comp-prof {
  color: #707070;
  color: var(--grey);
}

.btn-of-edit-comp-prof {
  color: #fff !important;
  background-color: #865ddd !important;
  font-weight: bold !important;
  border: 0;
  margin-top: 25px;
}

.btn-of-edit-comp-prof :hover {
  background-color: #865ddd !important;
}

.btn-of-edit-comp-prof :focus {
  background-color: #865ddd !important;
}

.btn-of-edit-comp-prof :active {
  background-color: #865ddd !important;
}

.text-area-des-edit-prof {
  min-height: 150px !important;
}
.inputs {
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  background: #00f;
  z-index: 999;
  position: absolute;
}
.intl-tel-input {
  width: 100%;
  margin-top: 8px;
}
.error-message {
  color: red;
}

.number-change {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  text-align: center;
  font-weight: bold;
  padding-top: 50px;
}

:root {
  --purple: #865ddd;
  --yellow: #865ddd;
  --grey: #707070;
  --light-grey: #c8c8c8;
  --light-purple: #f9f6ff;
  --white: #ffffff;
  --blue: #011f95;
}

.text-small {
  font-size: 0.9rem;
}

.messages-box,
.chat-box {
  height: 660px;
  overflow-y: scroll;
}

.rounded-lg {
  border-radius: 0.5rem;
}

input::-webkit-input-placeholder {
  font-size: 0.9rem;
  color: #999;
}

input::placeholder {
  font-size: 0.9rem;
  color: #999;
}

/* --------------------------------------------- */

/* enable absolute positioning */
.inner-addon {
  position: relative;
}

/* style glyph */
.inner-addon .glyphicon {
  position: absolute;
  padding: 10px;
  pointer-events: none;
}

/* align glyph */
.left-addon .glyphicon {
  left: 0px;
}
.right-addon .glyphicon {
  right: 0px;
}

/* add padding  */
.left-addon input {
  padding-left: 30px;
}
.right-addon input {
  padding-right: 30px;
}

/* ---------------------------------------------------- */

.user-namecoloe-blue {
  color: #011f95;
  color: var(--blue);
  font-weight: bold;
}

.skill-color-messaing {
  color: #707070;
  color: var(--grey);
}

.message-text-user-color {
  background-color: #865ddd !important;
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  max-width: 300px;
}

.messaging-head-main {
  color: #865ddd;
  color: var(--purple);
  font-weight: bold;
}

.message-sender-user-color {
  background-color: #c8c8c8;
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
}

.tezt-coodkn {
  color: #fff !important;
}

.trans {
  -webkit-transform: translate(-82px, 35px) !important;
          transform: translate(-82px, 35px) !important;
}

.messages-box::-webkit-scrollbar {
  width: 10px;
}

.messages-box::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.messages-box::-webkit-scrollbar-thumb {
  background: #888;
}
.messages-box::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.chat-box::-webkit-scrollbar {
  width: 10px;
}

.chat-box::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.chat-box::-webkit-scrollbar-thumb {
  background: #888;
}
.chat-box::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.mainmain {
  display: flex;
  justify-content: flex-end;
}
.styles_scrollable-div__prSCv {
  height: 555px !important;
  padding: 10px !important;
}
.styles_scrollable-div__prSCv::-webkit-scrollbar {
  width: 10px;
}

.styles_scrollable-div__prSCv::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.styles_scrollable-div__prSCv::-webkit-scrollbar-thumb {
  background: #888;
}
.styles_scrollable-div__prSCv::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.messages-box::-webkit-scrollbar {
  width: 10px;
  height: 8px !important;
}

.messages-box::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.messages-box::-webkit-scrollbar-thumb {
  background: #888;
}
.messages-box::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.image-upload > input {
  display: none;
}

:root {
    --purple: #865ddd;
    --yellow: #ffb44a;
    --grey: #707070;
    --light-grey: #c8c8c8;
    --blue: #011f95;
  }
  .css-2b097c-container{
    width: 81% !important;
  }
  .css-yk16xz-control{
    border-left: none !important;
  }
  /* .css-1wa3eu0-placeholder:after{
    font-size: 1rem;
    content: 'Skills';
  } */
  .css-1wa3eu0-placeholder { 
    visibility: hidden; 
    position: relative; 
} 
.css-1wa3eu0-placeholder:after { 
    visibility: visible; 
    position: absolute; 
    top: 0; 
    left: 0; 
    content: "Skills"; 
    color: rgb(200, 200, 200);
} 
:root {
    --purple: #865ddd;
    --yellow: #ffb44a;
    --grey: #707070;
    --light-grey: #c8c8c8;
    --blue: #011f95;
  }
  
  /* * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    scrollbar-width: 5px;
    scrollbar-color: black var(--grey);
  } */
  
  body {
    background-color: #fff;
  }
  
  
  
  .search-results-parent {
    width: 100%;
    height: 192vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* background-color: rgb(235, 22, 22); */
  }
  
  .heading-results-div {
    margin-top: 50px;
    height: 20vh;
    width: 92%;
    /* background-color: darkcyan; */
  }
  
  .search-for-jobs-results-head {
    font-size: 46px;
    color: #865ddd;
    color: var(--purple);
  }
  
  .search-for-jobs-results-para {
    font-size: 20px;
    color: #011f95;
    color: var(--blue);
  }
  
  .result-main-parent {
    height: 100vh;
    width: 95%;
    /* background-color: darkgreen; */
    display: flex;
  }
  
  .result-main-child-01 {
    height: 100vh;
    width: 25%;
    /* background-color: darkmagenta; */
  }
  
  .refine-search-section {
    height: 45vh;
    width: 90%;
    /* background-color: rgb(35, 218, 80); */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }
  
  .refine-search-btn-01 {
    height: 15%;
    width: 90%;
    color: #011f95;
    color: var(--blue);
    border: 0;
    background-color: #fff;
    box-shadow: 0 0 5px 5px #f2f2f2;
    border-radius: 10px;
    text-align: left;
    padding-left: 10px;
    font-weight: bold;
    cursor: pointer;
  }
  .refine-search-btn-01:focus {
    outline: 0;
  }
  /* Search Results Starts */
  
  .result-main-child-02 {
    height: 100vh;
    width: 60%;
    /* background-color: darkred; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .result-main-child-02 {
    height: 100%;
    width: 60%;
    /* background-color: chartreuse; */
    overflow: auto;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 20px;
  }
  
  .top-managment-box-1 {
    width: 100%;
    min-height: 40%;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 5px 5px #f2f2f2;
    margin-top: 20px;
  }
  
  .managment-box-head {
    width: 90%;
    height: 30%;
    /* background-color: darkgoldenrod; */
    margin-left: 20px;
    padding-top: 10px;
  }
  
  .experience-div {
    width: 90%;
    height: 20%;
    /* background-color: darkgreen; */
    display: flex;
    justify-content: space-between;
    margin-left: 20px;
  }
  
  .experience-div-01 {
    width: 30%;
    height: 100%;
    /* background-color: darkgrey; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .experience-div-02 {
    width: 30%;
    height: 100%;
    /* background-color: rgb(204, 20, 20); */
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .experience-div-03 {
    width: 30%;
    height: 100%;
    /* background-color: rgb(76, 235, 13); */
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  
  .left-to-icon-top-manag {
    margin-left: 5px;
    color: #707070;
    color: var(--grey);
  }
  
  .box-last-main {
    height: 60%;
    width: 90%;
    /* background-color: darkmagenta; */
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    padding-top: 10px;
    color: #707070;
    color: var(--grey);
  }
  
  .hiring-color {
    color: #707070;
    color: var(--grey);
  }
  
  .hiring-down-color {
    color: #c8c8c8;
    color: var(--light-grey);
  }
  
  /* Search Results Ends */
  
  /* ---------------------------------------------------- */
  
  /* Footer Work Starts Here */
  
  .footer-home {
    height: 50vh;
    width: 100%;
    background-color: #362658;
    display: flex;
    flex-direction: column;
    margin-top: 100px;
  }
  
  .footer-parent-1 {
    height: 80%;
    width: 100%;
    /* background-color: maroon; */
    display: flex;
  }
  
  .footer-parent-2 {
    height: 20%;
    width: 100%;
    /* background-color: mediumblue; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  
  .footer-child-01 {
    height: 100%;
    width: 75%;
    /* background-color: mediumseagreen; */
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
  
  .footer-child-02 {
    height: 100%;
    width: 25%;
    /* background-color: mediumvioletred; */
    display: flex;
    flex-direction: column;
  }
  
  .home-footer-sub-child-01 {
    height: 90%;
    width: 20%;
    margin-left: 10px;
    /* background-color: olive; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .home-footer-sub-child-04 {
    height: 90%;
    width: 15%;
    /* margin-left: 10px; */
    /* background-color: olive; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .foot-loc {
    color: #fff;
    font-size: 14px;
  }
  
  .size-sm {
    font-size: 12px;
    padding-top: 10px;
    color: #fff;
  }
  
  .pop-sear {
    margin-top: 5px;
    color: #fff;
    font-size: 14px;
  }
  
  .seekers {
    font-size: 18px;
    color: #fff;
  }
  
  .pad-lef-ho {
    padding-left: 20px;
  }
  
  .store-icon {
    margin-top: 10px;
    height: 19%;
    width: 45%;
    /* background-color: orange; */
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .apple-icon {
    margin-top: 10px;
    height: 20%;
    width: 45%;
    /* background-color: rgb(0, 255, 85); */
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .social-media-icons-foot {
    display: flex;
    height: 20%;
    width: 50%;
    /* background-color: #865ddd; */
  }
  
  .facebook-icon-div {
    height: 100%;
    width: 30%;
    /* background-color: orchid; */
  }
  
  .instagram-icon-div {
    height: 100%;
    width: 30%;
    /* background-color: orchid; */
    margin-left: 10px;
  }
  
  .linkedin-icon-div {
    height: 100%;
    width: 30%;
    /* background-color: orchid; */
    margin-left: 10px;
  }
  
  .google-play-icon {
    height: 100%;
    width: 100%;
  }
  
  .app-store-icon {
    height: 100%;
    width: 100%;
  }
  
  .give-margin-head {
    margin-top: 20px;
    color: #fff;
  }
  
  .follow-towhite {
    margin-top: 5px;
    color: #fff;
  }
  
  .social-media-icon {
    height: 100%;
    width: 100%;
  }
  
  .horizontal-footer-ruler {
    width: 40%;
    border-radius: 1px solid #707070;
    border-radius: 1px solid var(--grey);
  }
  
  .foot-nav {
    display: flex;
  }
  
  .left-to-foot-nav {
    margin-left: 80px;
  }
  
  .marg-to-home {
    margin-top: 20px;
    color: #fff;
    font-size: 10px;
  }
  /* Footer Work Ends Here */
  .place-ad h4 {
    -webkit-transform: rotate(
270deg
);
            transform: rotate(
270deg
);
    text-align: center;
    color: rgba(0, 0, 0, 0.4);
    font-size: 26px;
}
.place-ad {
  height: 700px;
  max-width: 230px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0px 5px 5px rgb(0 0 0 / 10%), 0px -3px 5px rgb(0 0 0 / 10%);
  background: var(--ad-banner-color);
}

.searcgh-hover-effect:hover{
  background: rgba(0, 0, 0, 0.1);
}


