:root {
  --purple: #865ddd;
  --yellow: #865ddd;
  --grey: #707070;
  --light-grey: #c8c8c8;
  --light-purple: #f9f6ff;
  --white: #ffffff;
}

.img-center {
  text-align: center;
}

.main-forget-recr {
  background-color: #fff;
  border-radius: 10px;
}

.for-recr-head {
  color: var(--purple);
}

.forget-pass-head-01 {
  color: var(--purple);
  font-size: 25px;
  text-align: center;
}

.para-forget-pass {
  font-size: 12px;
  text-align: center;
  color: var(--grey);
}

.label-color-f-pass {
  color: var(--grey);
}

#input-forget-pass {
  background: transparent;
  border: none;
  border-bottom: 1px solid #c8c8c8;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
  padding: 0;
  color: var(--light-grey);
  font-size: 14px;
  margin: 0;
  padding: 0;
}

.login-instead-for-pass {
  font-size: 12px;
  text-align: right;
  color: var(--purple);
  cursor: pointer;
}

.send-btn-for-pass {
  color: #fff !important;
  background-color: #865ddd !important;
  font-weight: bold !important;
  border: 0;
}

.send-btn-for-pass:hover {
  background-color: #865ddd !important;
}

.send-btn-for-pass:focus {
  background-color: #865ddd !important;
}

.send-btn-for-pass:active {
  background-color: #865ddd !important;
}
